import React, {useContext} from 'react';
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import {inquiryBasePath} from '../../App';
import {useLanguage} from '../../language/LanguageProvider';
import {OrderContentDto} from '../../lib/dtos/OrderContent';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {ContentService} from '../../lib/services/ContentService';
import {SEO} from '../../SEO';
import {loadServiceTypes} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {InquiryConfirmation} from './InquiryConfirmation';
import {InquiryContactInfoPage} from './InquiryContactInfoPage';
import {InquirySelectDatePage} from './InquirySelectDatePage';
import {InquirySelectServicePage} from './InquirySelectServicePage';
import {InquirySelectServiceTaskPage} from './InquirySelectServiceTaskPage';
import {InquirySummaryPage} from './InquirySummaryPage';

export const getInquiryConfirmationPath = (): string => `${inquiryBasePath}/bekreftelse`;
export const getInquirySummaryPath = (): string => `${inquiryBasePath}/oppsummering`;
export const getInquiryTimeSelectionPath = (): string => `${inquiryBasePath}/kontakt`;
export const getInquiryContactInfoPath = (): string => `${inquiryBasePath}/kontaktinfo`;
export const getInquirySelectServiceTaskPath = (serviceId: string | number): string =>
    `${inquiryBasePath}/valg/${serviceId}`;

export const useRedirectIfNoActiveInquiry = () => {
    const {state} = useContext(AppContext);
    const history = useHistory();
    const inquiryFromLocalStorage = localStorage.getItem('new_inquiry_data');

    React.useEffect(() => {
        const redirect = () => {
            if (!state.inquiry.inProgress && !inquiryFromLocalStorage) {
                history.replace(inquiryBasePath);
            }
        };
        redirect();
        // eslint-disable-next-line
    }, []);
};

export const InquiryPages: React.FC = () => {
    const {path, url} = useRouteMatch();
    const {dispatch} = useContext(AppContext);
    const {getLangString} = useLanguage();
    React.useEffect(() => {
        const service = new ContentService(M2HttpClient);
        const fetchData = async () => {
            try {
                const services: OrderContentDto = await service.getOrderContentDetails();
                dispatch(loadServiceTypes(services));
            } catch (e) {
                console.log(e);
                return;
            }
        };
        fetchData();
    }, [dispatch]);

    return (
        <>
            {/*TODO: Add description and photo*/}
            <SEO
                documentTitle={getLangString('MAKE_ORDER_BTN_TEXT')}
                title={getLangString('MAKE_ORDER_BTN_TEXT')}
                url={url}
            />
            <Switch>
                <Route path={`${path}/bekreftelse`} component={InquiryConfirmation} />
                <Route path={`${path}/oppsummering`} component={InquirySummaryPage} />
                <Route path={`${path}/kontaktinfo`} component={InquiryContactInfoPage} />
                <Route path={`${path}/kontakt`} component={InquirySelectDatePage} />
                <Route path={`${path}/valg/:serviceId`} component={InquirySelectServiceTaskPage} />
                <Route path={`${path}/*`}>
                    <Redirect to={path} />
                </Route>
                <Route path={`${path}`} component={InquirySelectServicePage} />
            </Switch>
        </>
    );
};
