import {ServiceDto} from '../lib/dtos/OrderContent';
import {OfferDetailsCustomer, OfferList} from '../lib/types/Admin';
import {CreateInquiry, ServiceDictionary} from '../lib/types/Inquiry';
import {AddressDict, UserInfo} from '../lib/types/User';
import {ActionType} from './actions';
import {getServices} from './adminAppReducer';

export type State = {
    user?: UserInfo;
    inquiry: CreateInquiry & {
        inProgress: boolean;
        isEditing: boolean;
    };
    allServices?: ServiceDictionary;
    offers?: OfferList[];
    offerDetails?: {[offerId: number]: OfferDetailsCustomer};
    displayLargePhoto: string | undefined;
};
export const initialState: State = {inquiry: {inProgress: false, isEditing: false}, displayLargePhoto: undefined};
export const appReducer = (state: State, action: ActionType): State => {
    switch (action.type) {
        case 'TOGGLE_DISPLAY_LARGE_PHOTO': {
            return {
                ...state,
                displayLargePhoto: action.payload.imgUrl,
            };
        }
        case 'SAVE_INQUIRY_CONTACT_INFO':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    contactInformation: action.payload.contactInformation,
                },
            };
        case 'LOAD_SERVICE_TYPES': {
            const createDict = (array: ServiceDto[]): ServiceDictionary => {
                return array.reduce(
                    (x, s) => ({
                        ...x,
                        [s.id]: {
                            id: s.id,
                            title: s.title,
                            children: s.children ? createDict(s.children) : undefined,
                            description: s.description,
                            archived: s.archived,
                        },
                    }),
                    {},
                );
            };
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    availableServices: createDict(action.payload.details.services),
                },
                allServices: getServices(action.payload.details.services),
            };
        }
        case 'SELECT_SERVICE_TYPE':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    inProgress: true,
                    selectedService: {
                        ...action.payload.service,
                    },
                },
            };
        case 'SELECT_SERVICE_TASKS': {
            if (state.inquiry.selectedService) {
                return {
                    ...state,
                    inquiry: {
                        ...state.inquiry,
                        selectedServiceTasks: [...action.payload.tasks],
                    },
                };
            } else {
                return state;
            }
        }
        case 'ADD_SPECIFICATION_TEXT':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    specification: {
                        ...state.inquiry?.specification,
                        description: action.payload.description,
                    },
                },
            };
        case 'UPDATE_SPECIFICATION_PHOTOS':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    photos: action.payload.photos,
                },
            };
        case 'ADD_CONSULTATION_TYPE':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    timeSchedule: {
                        ...state.inquiry?.timeSchedule,
                        consultationType: action.payload.consultationType,
                        timeOption: action.payload.timeOption,
                    },
                },
            };
        case 'CLEAR_INQUIRY_DATA':
            return {
                ...state,
                inquiry: {
                    inProgress: false,
                    isEditing: false,
                },
            };
        case 'EDITING_INQUIRY':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    isEditing: true,
                },
            };
        case 'EDITING_INQUIRY_DONE':
            return {
                ...state,
                inquiry: {
                    ...state.inquiry,
                    isEditing: false,
                },
            };
        case 'LOAD_USER_INFO': {
            const addressDict: AddressDict = action.payload.user.addresses.reduce(
                (dict, address) => ({
                    ...dict,
                    [address.customerAddressId]: {
                        ...address,
                    },
                }),
                {},
            );
            return {
                ...state,
                user: {
                    ...action.payload.user,
                    addresses: addressDict,
                },
            };
        }
        case 'UPDATE_USER_INFO_PHONE':
            return {
                ...state,
                user: {
                    ...state.user!,
                    phoneNumber: action.payload.phoneNumber,
                },
            };
        case 'UPDATE_USER_INFO_EMAIL':
            return {
                ...state,
                user: {
                    ...state.user!,
                    email: action.payload.email,
                },
            };

        case 'LOAD_OFFERS':
            return {
                ...state,
                offers: action.payload.offers.map((data) => ({
                    status: data.offerDecisionStatus,
                    offerId: data.offerId,
                    projectId: data.project.projectId,
                    dateCreated: new Date(data.createdAt),
                    title: `${data.project.street}, ${data.project.zip} ${data.project.location}`,
                    projectLeader: data.project.projectLeader,
                })),
            };

        case 'LOAD_OFFER_DETAILS': {
            const prevOfferDetails = state.offerDetails ? state.offerDetails[action.payload.offerDetails.offerId] : {};
            const {offerDetails} = action.payload;
            return {
                ...state,
                offerDetails: {
                    ...state.offerDetails,
                    [action.payload.offerDetails.offerId]: {
                        ...prevOfferDetails,
                        ...offerDetails,
                        dateCreated: new Date(offerDetails.createdAt),
                        dateUpdated: new Date(offerDetails.updatedAt),
                        fromDate: offerDetails.project.fromDate ? new Date(offerDetails.project.fromDate) : undefined,
                        toDate: offerDetails.project.toDate ? new Date(offerDetails.project.toDate) : undefined,
                        cordelCompanyOffers: offerDetails.cordelCompanyOffers.map((c) => ({
                            companyId: c.companyId,
                            files: c.files,
                        })),
                    },
                },
            };
        }
        default:
            return state;
    }
};
