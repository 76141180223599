import {FormControlLabel, Radio, RadioProps, useRadioGroup, useTheme} from '@mui/material';
import React from 'react';
import {RadioActiveIcon, RadioInActiveIcon} from './icons/RadioIcon';

export const CustomRadio: React.FC<RadioProps & {iconColor?: string}> = (props) => {
    return (
        <Radio
            onClick={props.onClick}
            value={props.value}
            checked={props.checked}
            icon={<RadioInActiveIcon size={24} color={props.iconColor} />}
            checkedIcon={<RadioActiveIcon size={24} color={props.iconColor} />}
            disableRipple={true}
            disabled={props.disabled}
        />
    );
};
type Props = {
    isDisabled?: boolean;
    labelText: string;
};
export const RadioButton: React.FC<RadioProps & Props> = (props) => {
    const radioGroup = useRadioGroup();
    const {palette} = useTheme();
    const isChecked = radioGroup?.value === props.value;

    return (
        <FormControlLabel
            control={
                <CustomRadio
                    aria-label={props['aria-label']}
                    iconColor={isChecked ? palette.formLine.active : palette.formLine.inActive}
                    value={props.value}
                    checked={isChecked}
                    disabled={props.isDisabled}
                />
            }
            labelPlacement={'start'}
            label={props.labelText}
            sx={{
                border: `1px solid ${isChecked ? palette.formLine.active : palette.formLine.inActive}`,
                color: isChecked ? palette.text.primary : palette.text.secondary,
                padding: {xs: '8px 8px 8px 16px', sm: '8px 16px 8px 24px'},
                justifyContent: 'space-between',
                margin: 0,
                marginTop: '-1px',
                zIndex: isChecked ? 2 : 1,
                width: '100%',
                '& span': {
                    color: 'inherit',
                },
            }}
        />
    );
};
