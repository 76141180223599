import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {AppBar, Box, IconButton, styled, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {inquiryBasePath} from '../App';
import {M2Logo} from '../assets/M2Logo';
import {loginRequest} from '../config/b2cConfig';
import {useLanguage} from '../language/LanguageProvider';
import {useIsSmallScreen, useScrollTop} from '../toolbox';
import {ButtonPrimaryLarge, ButtonPrimarySmall, ButtonPrimaryTextMedium} from './Buttons';
import {BurgerIcon} from './icons/BurgerIcon';
import {CloseIcon} from './icons/CloseIcon';
import {Container, CssGrid} from './Layout';
import {NavMenu} from './NavMenu';

const BackdropBlur = styled(Box)({
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(8px)',
    position: 'absolute',
});
const MenuBackground = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.accent.main,
    boxShadow: '0px 0px 12px rgb(18 18 18 / 10%)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    right: 102,
    bottom: 'calc(3vh + 40px + 78px)',
    [theme.breakpoints.down('sm')]: {
        right: 50,
        bottom: 80,
    },
}));
const LogoDecoration = styled(Box)(({theme}) => ({
    position: 'absolute',
    right: 40,
    bottom: 'calc(3vh + 40px)',
    '& svg': {
        display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
        right: 12,
        bottom: 32,
    },
}));
const MenuContent = styled(Box)(({theme}) => ({
    width: '90%',
    height: '80%',
    position: 'absolute',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
        padding: '24px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '24px 48px',
    },
}));
const MenuContainer = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
});
const MenuLayout = styled(Box)({
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
});
const MenuColumns = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'flex-end',
    margin: '32px 0',
    justifyContent: 'space-around',
    width: '100%',
    padding: '0 12%',

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'start',
        padding: 0,
    },
}));

export const NavBar: React.FC<{bgColor?: string}> = ({bgColor, children}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const openMenu = () => setIsOpen(true);
    const closeMenu = () => setIsOpen(false);

    const theme = useTheme();
    const isAuthenticated = useIsAuthenticated();
    const {instance, accounts} = useMsal();

    const handleLogin = async () => {
        if (accounts.length === 0) {
            instance.loginRedirect(loginRequest);
        }
        closeMenu();
    };
    const handleLogout = async () => {
        await instance.handleRedirectPromise();
        instance.logoutRedirect();
        closeMenu();
    };
    const history = useHistory();
    const goToMainPage = () => {
        history.push('/');
    };
    const goToInquiryPage = () => {
        history.push(inquiryBasePath);
    };
    const goToContactPage = () => {
        history.push('/kontakt-oss');
    };

    const isSmallScreen = useIsSmallScreen();

    const [navPosition, setNavPosition] = React.useState<'sticky' | 'absolute' | 'fixed'>('sticky');

    const location = useLocation();
    const {getLangString} = useLanguage();
    const scrollTop = useScrollTop();
    const useHeroStyling = location.pathname === '/' && scrollTop < window.innerHeight * 0.95;
    const NavButton = useHeroStyling ? ButtonPrimaryTextMedium : ButtonPrimarySmall;

    React.useEffect(() => {
        location.pathname === '/'
            ? useHeroStyling
                ? setNavPosition('absolute')
                : setNavPosition('fixed')
            : setNavPosition('sticky');
    }, [scrollTop, location, useHeroStyling]);

    return (
        <>
            <AppBar
                sx={{
                    backgroundColor: useHeroStyling ? 'transparent' : bgColor,
                    transform: `translateY(${useHeroStyling && scrollTop > 200 ? '-100px' : '0px'})`,
                    position: navPosition,
                    transition: `transform ease-out 0.3s`,
                    boxShadow: useHeroStyling ? 'none' : '',
                    py: 1,
                }}
            >
                <Box sx={{display: 'grid', gridTemplateColumns: '1fr'}}>
                    <Box
                        sx={{
                            gridRow: '1',
                            gridColumn: '1',
                            alignSelf: 'center',
                        }}
                    >
                        <Container fullWidth={true}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <IconButton onClick={openMenu} sx={{ml: '-8px', mr: '8px'}}>
                                        <BurgerIcon
                                            size={32}
                                            color={
                                                useHeroStyling ? theme.palette.accent.main : theme.palette.text.primary
                                            }
                                        />
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{
                                        visibility: useHeroStyling ? 'hidden' : 'visible',
                                        cursor: 'pointer',
                                    }}
                                    onClick={goToMainPage}
                                >
                                    <M2Logo size={isSmallScreen ? 50 : 72} color={theme.palette.text.primary} />
                                </Box>
                            </Box>
                        </Container>
                    </Box>

                    <Box
                        sx={{
                            gridRow: '1',
                            gridColumn: '1',
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <Container>
                            <CssGrid sx={{alignItems: 'center', py: 1}}>
                                <Box
                                    sx={{
                                        gridColumn: '2 / span 6',
                                        pointerEvents: 'initial',
                                        '& > Button': {mr: 3},
                                    }}
                                >
                                    {children ? (
                                        children
                                    ) : (
                                        <>
                                            {/* {!isSmallScreen && !isAuthenticated && (
                                                <NavButton color={'info'} onClick={handleLogin}>
                                                    {getLangString('SIGN_IN')}
                                                </NavButton>
                                            )} */}
                                            {!location.pathname.match(inquiryBasePath) && (
                                                <NavButton color={'info'} onClick={goToInquiryPage}>
                                                    {getLangString('MAKE_ORDER_BTN_TEXT')}
                                                </NavButton>
                                            )}
                                            <NavButton color={'info'} onClick={goToContactPage}>
                                                {getLangString('CONTACT_US')}
                                            </NavButton>
                                        </>
                                    )}
                                </Box>
                            </CssGrid>
                        </Container>
                    </Box>
                </Box>
            </AppBar>
            <MenuContainer
                sx={{
                    width: isOpen ? '100vw' : 0,
                    height: isOpen ? '100vh' : 0,
                    opacity: isOpen ? 1 : 0,
                    zIndex: 10000,
                }}
            >
                <BackdropBlur />
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        opacity: isOpen ? 1 : 0,
                        transition: `opacity ease-out ${isOpen ? '0.3s' : '0.1s'}`,
                    }}
                    onClick={closeMenu}
                >
                    <MenuBackground />
                    <LogoDecoration>
                        <M2Logo size={isSmallScreen ? 160 : 260} color={theme.palette.text.primary} />
                    </LogoDecoration>
                    <MenuContent>
                        <IconButton onClick={closeMenu} sx={{ml: -1}}>
                            <CloseIcon size={32} />
                        </IconButton>
                        <MenuLayout>
                            <MenuColumns>
                                <Box marginBottom={5} width={'50%'}>
                                    <NavMenu />
                                </Box>
                                <Box
                                    sx={{
                                        mb: 5,
                                        [theme.breakpoints.up('md')]: {
                                            transform: 'translateY(-17px)', // Client wanted the button to be visually aligned with the menu.
                                        },
                                    }}
                                    width={'50%'}
                                >
                                    {/* {isAuthenticated ? (
                                        <ButtonPrimaryLarge onClick={handleLogout} sx={{color: 'info.main'}}>
                                            {getLangString('SIGN_OUT')}
                                        </ButtonPrimaryLarge>
                                    ) : (
                                        <ButtonPrimaryLarge onClick={handleLogin}>
                                            {getLangString('SIGN_IN')}
                                        </ButtonPrimaryLarge>
                                    )} */}
                                </Box>
                            </MenuColumns>
                        </MenuLayout>
                    </MenuContent>
                </Box>
            </MenuContainer>
        </>
    );
};
